import {
    getDefaultSearchBarObj,
    navigateToUrl,
    setDefaultSearchBarData,
    handleCounter,
    initializeLitePicker,
    handleClearFilter,
    getTranslatedString
} from '../../../../asset/ui/src';
import { clearCookie, getCookie, setCookie } from '../../../../asset/ui/src/js/cookies';
import { pushSearchBarDataLayer } from '../../../../asset/ui/src/js/data-layers';

const windowFunctions = {
    refreshSearchBarDestinations: refreshSearchBarDestinations,
    getSearchBarSubDestinations: getSearchBarSubDestinations,
    checkLevelTwoCheckboxes: checkLevelTwoCheckboxes,
    checkLevelThreeCheckboxes: checkLevelThreeCheckboxes,
    checkSubDestinationToggle: checkSubDestinationToggle,
    changeLitepickerYearNr: changeLitepickerYearNr,
    saveSearchBarDate: saveSearchBarDate,
    saveSearchBarDuration: saveSearchBarDuration,
    saveSearchBarTravelers: saveSearchBarTravelers,
    saveSearchBarPriceType3: saveSearchBarPriceType3
};
Object.assign( window, windowFunctions );

export default () =>
{
    apos.util.onReady( () =>
    {
        apos.util.runPlayers();
    } );

    apos.util.widgetPlayers.searchBar = {
        selector: '[id=search-bar-form]',
        player: function( el )
        {
            const playerData = el.dataset.playerdata ? JSON.parse( el.dataset.playerdata ) : null;
            if( playerData?.clearSBD || playerData?.showDateOnSearchbar === false )
            {
                // eslint-disable-next-line no-undef
                setDefaultSearchBarData();
            }

            initializeLitePicker();
            refreshSearchBarDestinations(); // on page reload / navigating to list page
            handleCounter( 1, 9 );

            document.querySelector( '#destination-search-popup-search' ).addEventListener( 'input', function( event )
            {
                filterSearchBarDestinations( this.value );
            } );

            const sbd_cookie = getCookie( 'dw_sbd' );
            if( sbd_cookie )
            {
                const searchBarCookie = JSON.parse( sbd_cookie );
                if( !searchBarCookie.destinationsType )
                {
                    searchBarCookie.destinationsType = setDestinationType( playerData?.destinationsTypeArray );
                    setCookie( 'dw_sbd', JSON.stringify( searchBarCookie ) );
                    setSearchBarType();
                }
            }

            // Moved handleClearFilter() to the bottom because the function adds a mutation observer to check any changes made to the searchbar elements
            // Because setSearchBarType() was called after handleClearFilter(), it would trigger that mutation observer when setting the destinations type from setSearchBarType() making the clearFilter pop up even on default values like "null or 'all'";
            handleClearFilter();

            const destinationType = setDestinationType( playerData?.destinationsTypeArray ) ? setDestinationType( playerData?.destinationsTypeArray ).value : null;
            checkAndResetAvailableDestinations( destinationType, true );
            setCountForSelectedSearchBarType();

            document.getElementById( 'search-bar-form-submit' ).disabled = false; // set active if user uses 'Go Back' button

            window.submitSearchForm = async function()
            {
                const searchButton = document.getElementById( 'search-bar-form-submit' ); // get button and disable it
                searchButton.disabled = true;

                let searchBarCookie = null;
                if( getCookie( 'dw_sbd' ) )
                {
                    searchBarCookie = JSON.parse( getCookie( 'dw_sbd' ) );

                    if( searchBarCookie.showDateOnSearchbar === false )
                    {
                        searchBarCookie = getDefaultSearchBarObj();
                    }
                    if( !searchBarCookie.countryAreas )
                    {
                        searchBarCookie.countryAreas = null;
                    }
                    if( !searchBarCookie.destinationsType )
                    {
                        searchBarCookie.destinationsType = setDestinationType( playerData?.destinationsTypeArray );
                    }
                    if( !searchBarCookie.parentDestination )
                    {
                        searchBarCookie.parentDestination = null;
                    }
                    if( !searchBarCookie.seoFriendlyName )
                    {
                        searchBarCookie.seoFriendlyName = null;
                    }
                    if( !searchBarCookie.date )
                    {
                        searchBarCookie.date = null;
                        searchBarCookie.flexibleDates = false;
                    }
                    if( !searchBarCookie.duration )
                    {
                        searchBarCookie.duration = null;
                    }
                    if( !searchBarCookie.travelers )
                    {
                        searchBarCookie.travelers = 2;
                    }

                    searchBarCookie.showDateOnSearchbar = true;
                    setCookie( 'dw_sbd', JSON.stringify( searchBarCookie ) );
                }
                else
                {
                    await setCookie( 'dw_sbd', JSON.stringify( getDefaultSearchBarObj() ) );
                    searchBarCookie = JSON.parse( getCookie( 'dw_sbd' ) );
                }

                pushSearchBarDataLayer();

                const response = await apos.http.post( '/getListingUrl', { body: { destinationsType: searchBarCookie.destinationsType } } );
                if( response.status === 'OK' )
                {
                    navigateToUrl( response.redirectUrl, false, true );
                }
                else
                {
                    searchButton.disabled = false; // re-enable button
                }

                return false;
            };
        }
    };
};



function filterSearchBarDestinations( filter )
{
    const els = document.querySelectorAll( '.destination-checkbox' );
    for( let i = 0; i < els.length; i++ )
    {
        const destinationTitle = els[i].dataset.title.toLowerCase();
        const search = filter.toLowerCase();
        els[i].style.display = destinationTitle.search( search ) > -1 ? 'flex' : 'none';
    }
}

function refreshSearchBarDestinations( clearCheckedDestinations, skipAvailableDestinationsReset )
{
    toggleSearchBarDestinationsCount( 'search-bar-destinations' );
    getSearchBarSubDestinations( clearCheckedDestinations, skipAvailableDestinationsReset );
};

function toggleSearchBarDestinationsCount( id )
{
    const searchBarDestinationsType = document.querySelector( 'input[name="searchBarDestinationType"][type=radio]:checked' );
    const searchBarDestinations = document.getElementById( id );

    if( searchBarDestinationsType && searchBarDestinationsType.value && searchBarDestinations )
    {
        const searchBarDestinationsCounts = searchBarDestinations.querySelectorAll( 'span.filter-label-amount' );
        const searchBarDestinationsLabels = searchBarDestinations.querySelectorAll( 'label.custom-checkbox' );

        switch( searchBarDestinationsType.value )
        {
        case 'all':
            searchBarDestinationsCounts.forEach( function( span, i )
            {
                if( span.dataset.totalCount )
                {
                    span.innerHTML = '(' + span.dataset.totalCount + ')';
                    searchBarDestinationsLabels[i].style.display = 'flex';
                }
                else
                {
                    span.innerHTML = '(0)';
                    const checkbox = searchBarDestinationsLabels[i].querySelector( 'input[type=checkbox]' );
                    checkbox.checked = false;
                    checkbox.dataset.emptyCount = true;
                    searchBarDestinationsLabels[i].style.display = 'none';
                }
            } );
            break;

        case 'liveaboards':
            searchBarDestinationsCounts.forEach( function( span, i )
            {
                if( span.dataset.liveaboardCount )
                {
                    span.innerHTML = '(' + span.dataset.liveaboardCount + ')';
                    searchBarDestinationsLabels[i].style.display = 'flex';
                }
                else
                {
                    span.innerHTML = '(0)';
                    searchBarDestinationsLabels[i].querySelector( 'input[type=checkbox]' ).checked = false;
                    searchBarDestinationsLabels[i].style.display = 'none';
                }
            } );
            break;

        case 'offers':
            console.log( 'no offers' );
        // TODO: add offers
        }
    }
}

window.saveSearchBarDestinations = async function()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const selectedDestinations = [];
    let seoFriendlyName = null;
    let parentDestination = null;
    const searchBarDestinationsType = document.querySelector( 'input[name="searchBarDestinationType"][type=radio]:checked' );
    const searchBarContainer = document.getElementById( 'search-bar-destination-container' );
    const searchBarQuestion = searchBarContainer.querySelector( '.search-input-question' );
    const levelOneDestinations = document.getElementById( 'search-bar-destinations' );
    const levelOneCheckboxes = levelOneDestinations.querySelectorAll( 'input[type=checkbox]:checked' );

    // If there's only 1 destination selected,we proceed to handle subDestinations.
    // Otherwise, if there are more than 1, save all of their fullCountryAreas.
    // Also, set the innerHTML of the search bar question.
    if( levelOneCheckboxes && levelOneCheckboxes.length === 1 )
    {
        parentDestination = levelOneCheckboxes[0].dataset.countryArea;

        const subDestinations = document.getElementById( 'search-bar-subdestinations' );
        const subDestinationsToggle = subDestinations.querySelector( 'input[name="searchBarSubDestinationsToggle"]' );

        // if the toggle is checked, it means no subDestination is selected, so we save the lvl1 destination's fullCountryArea.
        // Otherwise, we handle subDestinations.
        // Also, set the innerHTML of the search bar question.
        if( subDestinationsToggle.checked === true )
        {
            selectedDestinations.push( {
                label: levelOneCheckboxes[0].dataset.seoFriendlyName,
                value: levelOneCheckboxes[0].dataset.countryArea
            } );
            seoFriendlyName = levelOneCheckboxes[0].dataset.seoFriendlyName;
            searchBarQuestion.innerHTML = levelOneCheckboxes[0].value;

            sessionStorage.setItem( 'dw_cprod', JSON.stringify( {
                formDestination: searchBarQuestion.innerHTML.trimEnd().trimStart(),
                url: window.location.pathname
            } ) );
        }
        else
        {
            const levelTwoDestinations = Array.from( subDestinations.querySelectorAll( 'input[name="levelTwoDestination"]:checked' ) );
            const levelThreeDestinations = Array.from( subDestinations.querySelectorAll( 'input[name="levelThreeDestination"][data-empty-count="false"]' ) );

            let nrOfLevelThreeSelected = 0;

            levelTwoDestinations.forEach( function( levelTwo )
            {
                // For every lvl 2 subDestination, if it's checked, verify if it has a lvl 3 subDestination.
                const levelThreeArray = levelThreeDestinations.filter( function( levelThree )
                {
                    return levelThree.dataset.fullCountryArea.includes( levelTwo.dataset.fullCountryArea );
                } );

                // If there are lvl 3 subDestinations, handle them.
                // Otherwise save the lvl 2 destination's fullCountryArea.
                if( levelThreeArray && levelThreeArray.length > 0 )
                {
                    // Check if all lvl 3 subDestinations are checked, or if none of them are.
                    levelThreeArray.forEach( function( levelThree )
                    {
                        if( levelThree.checked === true )
                        {
                            nrOfLevelThreeSelected++;
                        }
                    } );

                    // If no lvl 3 subDestination is selected, or if all of them are, save the parent lvl 2 destination's fullCountryArea.
                    // Otherwise, save each lvl 3 subDestination's fullCountryArea.
                    if( ( nrOfLevelThreeSelected === 0 ) || ( nrOfLevelThreeSelected === levelThreeArray.length ) )
                    {
                        selectedDestinations.push( {
                            label: levelTwo.dataset.seoFriendlyName,
                            value: levelTwo.dataset.countryArea
                        } );
                    }
                    else
                    {
                        levelThreeArray.forEach( function( levelThree )
                        {
                            if( levelThree.checked === true )
                            {
                                selectedDestinations.push( {
                                    label: levelThree.dataset.seoFriendlyName,
                                    value: levelThree.dataset.countryArea
                                } );
                            }
                        } );
                    }
                }
                else
                {
                    selectedDestinations.push( {
                        label: levelTwo.dataset.seoFriendlyName,
                        value: levelTwo.dataset.countryArea
                    } );
                }
            } );

            // Set the innerHTML of the search bar question if there's only 1 lvl 2 destination selected.
            // Otherwise, set the innerHTML to the lvl 1 destination's value.
            if( levelTwoDestinations.length === 1 )
            {
                const levelThreeArray = levelThreeDestinations.filter( function( levelThree )
                {
                    return levelThree.dataset.fullCountryArea.includes( levelTwoDestinations[0].dataset.fullCountryArea );
                } );

                // If there's 1 level 3 destinations, set the innerHTML to it's value.
                // Otherwise, set the innerHTML to the parent lvl 2 desitnation.
                if( levelThreeArray && levelThreeArray.length > 0 )
                {
                    if( nrOfLevelThreeSelected === 1 )
                    {
                        levelThreeArray.forEach( function( levelThree )
                        {
                            if( levelThree.checked === true )
                            {
                                searchBarQuestion.innerHTML = levelThree.value;
                                seoFriendlyName = levelThree.dataset.seoFriendlyName;
                            }
                        } );
                    }
                    else if( ( nrOfLevelThreeSelected === 0 ) || ( nrOfLevelThreeSelected === levelThreeArray.length ) )
                    {
                        searchBarQuestion.innerHTML = levelTwoDestinations[0].value;
                        seoFriendlyName = levelTwoDestinations[0].dataset.seoFriendlyName;
                    }
                    else
                    {
                        searchBarQuestion.innerHTML = levelTwoDestinations[0].value;
                    }
                }
                else
                {
                    searchBarQuestion.innerHTML = levelTwoDestinations[0].value;
                    seoFriendlyName = levelTwoDestinations[0].dataset.seoFriendlyName;
                }
            }
            else if( levelTwoDestinations.length > 1 )
            {
                searchBarQuestion.innerHTML = levelOneCheckboxes[0].value;
            }
        }
    }
    else if( levelOneCheckboxes && levelOneCheckboxes.length > 1 )
    {
        levelOneCheckboxes.forEach( function( levelOne )
        {
            selectedDestinations.push( {
                label: levelOne.dataset.seoFriendlyName,
                value: levelOne.dataset.countryArea
            } );
        } );

        searchBarQuestion.innerHTML = await getTranslatedString( 'multipleDestinationsSelected' );
    }
    else
    {
        searchBarQuestion.innerHTML = await getTranslatedString( 'whereDoYouWantToGo' );
    }

    searchBarData.countryAreas = selectedDestinations.length > 0 ? selectedDestinations : null;
    searchBarData.parentDestination = parentDestination || null;
    searchBarData.seoFriendlyName = seoFriendlyName || null;

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
};

export function saveSearchBarPriceType3()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarRoute = document.getElementById( 'search-bar-price-type-3-container' );
    const searchBarDateQuestion = searchBarRoute.querySelector( '.search-input-question' );
    const selectedSearchBarRoute = document.querySelector( 'input[name="searchBarPriceType3"]:checked' );

    if( selectedSearchBarRoute )
    {
        searchBarData.priceType3 = {
            label: selectedSearchBarRoute.dataset.priceType3Label,
            value: selectedSearchBarRoute.value
        };
        searchBarDateQuestion.innerHTML = selectedSearchBarRoute.dataset.priceType3Label;
    }

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
    handlePseudoSearchBarReset( selectedSearchBarRoute );
};

export async function saveSearchBarDate( locale )
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarDate = document.getElementById( 'search-bar-date-container' );
    const searchBarDateQuestion = searchBarDate.querySelector( '.search-input-question' );
    const searchBarCheckbox = searchBarDate.querySelector( 'input[type=checkbox]:checked' );
    const selectedSearchBarDate = document.getElementById( 'searchBarSelectedDate' );

    if( selectedSearchBarDate )
    {
        searchBarData.date = selectedSearchBarDate.value;
        searchBarData.flexibleDates = !!searchBarCheckbox;
        searchBarData.showDateOnSearchbar = true;

        if( locale )
        {
            let translatedDate = new Date( selectedSearchBarDate.value );
            translatedDate = translatedDate.toLocaleDateString( locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            } );
            searchBarDateQuestion.innerHTML = translatedDate;
        }
    }
    else
    {
        searchBarDateQuestion.innerHTML = await getTranslatedString( 'multipleDestinationsSelected' ) + '?';
        searchBarData.date = null;
    }

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
};

export async function saveSearchBarDuration()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarDuration = document.getElementById( 'search-bar-duration-container' );
    const searchBarDurationQuestion = searchBarDuration.querySelector( '.search-input-question' );

    const selectedSearchBarDuration = searchBarDuration.querySelector( 'input[type=radio]:checked' );

    if( selectedSearchBarDuration && selectedSearchBarDuration.value )
    {
        searchBarDurationQuestion.innerHTML = selectedSearchBarDuration.dataset.label;
        searchBarData.duration = {
            label: selectedSearchBarDuration.dataset.label,
            value: selectedSearchBarDuration.value
        };
        searchBarData.showDateOnSearchbar = true;
    }
    else
    {
        searchBarDurationQuestion.innerHTML = await getTranslatedString( 'howLong' );
        searchBarData.duration = null;
    }

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
};

export async function saveSearchBarTravelers()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarTravelers = document.getElementById( 'search-bar-travelers-container' );
    const searchBarTravelersQuestion = searchBarTravelers.querySelector( '.search-input-question' );

    const selectedSearchBarTravelers = searchBarTravelers.querySelector( 'input[type=number]' );
    if( selectedSearchBarTravelers && selectedSearchBarTravelers.value )
    {
        searchBarTravelersQuestion.innerHTML = selectedSearchBarTravelers.value > 1 ? selectedSearchBarTravelers.value + ' personen' : selectedSearchBarTravelers.value + ' persoon';
        searchBarData.travelers = selectedSearchBarTravelers.value;
        searchBarData.showDateOnSearchbar = true;
    }
    else
    {
        searchBarTravelersQuestion.innerHTML = await getTranslatedString( 'withWhom' );
        searchBarData.travelers = null;
    }

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
};

function setDestinationType( destinationsTypeArray )
{
    let destinationType = null;
    const pathname = window.location.pathname;

    if( pathname.includes( 'liveaboards' ) )
    {
        destinationType = destinationsTypeArray.find( function ( item )
        {
            return item.value === 'liveaboards';
        } );
    }
    else if( pathname.includes( 'duikresorts' ) )
    {
        destinationType = destinationsTypeArray.find( function ( item )
        {
            return item.value === 'accommodations';
        } );
    }
    else if( pathname.includes( 'aanbiedingen' ) )
    {
        destinationType = destinationsTypeArray.find( function ( item )
        {
            return item.value === 'offers';
        } );
    }
    else if( pathname.includes( 'duikvakanties' ) )
    {
        destinationType = destinationsTypeArray.find( function ( item )
        {
            return item.value === 'all';
        } );
    }

    return destinationType;
}

export function changeLitepickerYearNr( method )
{
    const litepickerYearSelector = document.querySelector( '.search-bar-litepicker-year-selector' );
    const selectedYear = document.querySelector( '.search-bar-litepicker-selected-year' );

    // only increment for 4 years, only decrement until current year
    if( method === 'increment' && parseInt( litepickerYearSelector.dataset.selectedYear ) < parseInt( litepickerYearSelector.dataset.currentYear ) + 4 )
    {
        selectedYear.innerHTML++;
        litepickerYearSelector.dataset.selectedYear++;
    }
    else if( method === 'decrement' && parseInt( litepickerYearSelector.dataset.selectedYear ) > parseInt( litepickerYearSelector.dataset.currentYear ) )
    {
        selectedYear.innerHTML--;
        litepickerYearSelector.dataset.selectedYear--;
    }
}

async function getSearchBarSubDestinations( clearCheckedDestinations, skipAvailableDestinationsReset )
{
    const searchBarDestinationPopup = document.getElementById( 'search-bar-destination-popup' );
    const searchBarDestinationGroup = document.getElementById( 'search-bar-destination-group' );
    const searchBarDestinationGroupWrapper = searchBarDestinationGroup.querySelector( '.destination-search-popup-wrapper' );
    const destinationCheckboxes = searchBarDestinationGroupWrapper.querySelectorAll( 'input[type=checkbox]:checked' );
    const subDestinations = document.getElementById( 'search-bar-subdestinations' );
    const multipleDestinationsSelected = document.getElementById( 'multiple-destinations-selected' );
    const popupButtonsStyle = document.getElementById( 'popup-buttons' );
    const allDestinationsRadiobutton = searchBarDestinationGroup.querySelector( 'input[ name="searchBarDestinationType" ]' );
    allDestinationsRadiobutton.checked = destinationCheckboxes.length <= 0;

    if( subDestinations )
    {
        searchBarDestinationPopup.classList.remove( 'subdestinations-opened' );
        popupButtonsStyle.classList.remove( 'popup-buttons-destination-opened' );
        subDestinations.remove();
    }

    if( destinationCheckboxes && destinationCheckboxes.length === 1 )
    {
        if( multipleDestinationsSelected )
        {
            multipleDestinationsSelected.remove();
        }

        const countryArea = destinationCheckboxes.item( 0 ).dataset.countryArea;

        const response = await apos.http.post( '/getSearchBarSubDestinations', { body: { countryArea: countryArea } } );
        if( response.status === 'OK' )
        {
            searchBarDestinationPopup.classList.add( 'subdestinations-opened' );
            popupButtonsStyle.classList.add( 'popup-buttons-destination-opened' );

            if( clearCheckedDestinations )
            {
                allDestinationsRadiobutton.checked = true;
                searchBarDestinationPopup.classList.remove( 'subdestinations-opened' );
                popupButtonsStyle.classList.remove( 'popup-buttons-destination-opened' );
            }
            else
            {
                searchBarDestinationGroup.insertAdjacentHTML( 'beforeend', response.data?.subDestinationsData );
                toggleSearchBarDestinationsCount( 'search-bar-subdestinations' );
                setSelectedSubDestinationsCheckboxes();
                checkSubDestinationCheckboxes();
            }

            const checkedSearchBarQuestion = document.querySelector( 'input[name="searchBarType"]:checked' );

            if( !skipAvailableDestinationsReset )
            {
                const skipReset = !skipAvailableDestinationsReset;
                const destinationType = checkedSearchBarQuestion ? checkedSearchBarQuestion.value : null;
                checkAndResetAvailableDestinations( destinationType, skipReset );
                setCountForSelectedSearchBarType();

                const destinations = document.getElementById( 'search-bar-destinations' );
                const selectedDestination = destinations.querySelector( 'input[type=checkbox]:checked' );
                if( selectedDestination )
                {
                    const selectedDestinationValue = selectedDestination.parentElement.querySelector( '[class="filter-label-amount"]' ).innerHTML;
                    const subDestinationCount = document.getElementById( 'sub-destinations-all' );
                    if( subDestinationCount )
                    {
                        subDestinationCount.innerHTML = selectedDestinationValue;
                    }
                }
            }
        }
        else
        {
            // console.log( "-> response.message", response.status, response.code );
        }
    }
    else if( destinationCheckboxes && destinationCheckboxes.length > 1 )
    {
        if( !multipleDestinationsSelected )
        {
            searchBarDestinationPopup.classList.add( 'subdestinations-opened' );
            popupButtonsStyle.classList.add( 'popup-buttons-destination-opened' );
            const message = '<div id="multiple-destinations-selected">Filteren op gebied kan niet als je meerdere bestemmingen hebt gekozen.</div>';
            searchBarDestinationGroup.insertAdjacentHTML( 'beforeend', message );
        }
    }

    if( clearCheckedDestinations )
    {
        destinationCheckboxes.forEach( function( checkbox )
        {
            checkbox.checked = false;
        } );

        allDestinationsRadiobutton.checked = true;
        searchBarDestinationPopup.classList.remove( 'subdestinations-opened' );
        popupButtonsStyle.classList.remove( 'popup-buttons-destination-opened' );

        if( multipleDestinationsSelected )
        {
            multipleDestinationsSelected.remove();
        }
    }
}

function checkSubDestinationToggle()
{
    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    const destinationsToggle = searchBarSubDestinations.querySelector( 'input[name="searchBarSubDestinationsToggle"]' );
    const subDestinations = searchBarSubDestinations.querySelectorAll( 'input[name="levelTwoDestination"], input[name="levelThreeDestination"]' );

    if( destinationsToggle.checked === true )
    {
        subDestinations.forEach( function( subDestination )
        {
            subDestination.checked = false;
        } );
    }
}

function setSelectedSubDestinationsCheckboxes()
{
    let searchBarData = {};
    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    const subDestinations = searchBarSubDestinations.querySelectorAll( 'input[name="levelTwoDestination"], input[name="levelThreeDestination"]' );

    const subDestinationsArray = Array.from( subDestinations );
    const selectedLevelThree = [];

    if( searchBarData.countryAreas && searchBarData.countryAreas.length > 0 )
    {
        subDestinations.forEach( function( subDestination )
        {
            const selectedSubDestination = searchBarData.countryAreas.findIndex( function( countryArea )
            {
                return countryArea.value === subDestination.dataset.countryArea;
            } );
            if( selectedSubDestination > -1 )
            {
                subDestination.checked = true;
                selectedLevelThree.push( subDestination );
            }

            if( subDestination.name === 'levelTwoDestination' && subDestination.checked === true )
            {
                checkLevelThreeCheckboxes( subDestination );
            }
        } );
    }

    if( selectedLevelThree.length > 0 )
    {
        selectedLevelThree.forEach( function( levelThree )
        {
            const parentLevelTwo = subDestinationsArray.find( function( destination )
            {
                if( destination.name === 'levelTwoDestination' && levelThree.dataset.fullCountryArea.startsWith( destination.dataset.fullCountryArea ) )
                {
                    return destination;
                }
            } );
            parentLevelTwo.checked = true;
        } );
    }
}

function checkSubDestinationCheckboxes()
{
    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    const destinationsToggle = searchBarSubDestinations.querySelector( 'input[name="searchBarSubDestinationsToggle"]' );
    const subDestinations = searchBarSubDestinations.querySelectorAll( 'input[name="levelTwoDestination"], input[name="levelThreeDestination"]' );
    let noDestinationsChecked = true;

    subDestinations.forEach( function( subDestination )
    {
        if( subDestination.checked )
        {
            destinationsToggle.checked = false;
            noDestinationsChecked = false;
        }
    } );

    if( noDestinationsChecked === true )
    {
        destinationsToggle.checked = true;
    }
}

function checkLevelThreeCheckboxes( levelTwo )
{
    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    let levelThreeDestinations = searchBarSubDestinations.querySelectorAll( 'input[name="levelThreeDestination"]' );

    if( levelThreeDestinations )
    {
        levelThreeDestinations = Array.from( levelThreeDestinations );

        const levelThreeArray = levelThreeDestinations.filter( function( levelThree )
        {
            return levelThree.dataset.fullCountryArea.includes( levelTwo.dataset.fullCountryArea );
        } );

        if( levelTwo.checked === true )
        {
            levelThreeArray.forEach( function( levelThree )
            {
                levelThree.checked = true;
            } );
        }
        else
        {
            levelThreeArray.forEach( function( levelThree )
            {
                levelThree.checked = false;
            } );
        }
    }

    checkSubDestinationCheckboxes();
}

function checkLevelTwoCheckboxes()
{
    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    const levelTwoDestinations = Array.from( searchBarSubDestinations.querySelectorAll( 'input[name="levelTwoDestination"]' ) );
    const levelThreeDestinations = Array.from( searchBarSubDestinations.querySelectorAll( 'input[name="levelThreeDestination"]' ) );
    let noLevelThreeChecked = true;

    levelThreeDestinations.forEach( function( levelThree )
    {
        const parent = levelTwoDestinations.filter( function( levelTwo )
        {
            return levelThree.dataset.fullCountryArea.includes( levelTwo.dataset.fullCountryArea );
        } );
        if( levelThree.checked === true )
        {
            parent[0].checked = true;
            noLevelThreeChecked = false;
        }
        if( noLevelThreeChecked === true )
        {
            parent[0].checked = false;
        }
    } );

    checkSubDestinationCheckboxes();
}

function handlePseudoSearchBarReset( el )
{
    const valueToReset = el.getAttribute( 'data-reset-search-item' );
    if( valueToReset )
    {
        clearCookie( 'dw_sbd', [ valueToReset ] );
    }
}

window.saveSearchBarType = function()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarRoute = document.getElementById( 'search-bar-type-container' );
    const searchBarDateQuestion = searchBarRoute.querySelector( '.search-input-question' );
    const selectedSearchBarType = document.querySelector( 'input[name="searchBarType"]:checked' );

    if( selectedSearchBarType )
    {
        searchBarData.destinationsType = {
            label: selectedSearchBarType.dataset.priceTypeLabel,
            value: selectedSearchBarType.value
        };
        searchBarDateQuestion.innerHTML = selectedSearchBarType.dataset.priceTypeLabel;
        searchBarData.showDateOnSearchbar = true;
    }

    setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );

    checkAndResetAvailableDestinations( selectedSearchBarType.value );
    setCountForSelectedSearchBarType();
};
function setSearchBarType()
{
    let searchBarData = {};

    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }

    const searchBarTypeEl = document.getElementById( 'search-bar-type-container' );
    if( !searchBarTypeEl )
    {
        return;
    }

    const searchBarDateQuestion = searchBarTypeEl.querySelector( '.search-input-question' );
    if( searchBarData.destinationsType )
    {
        const selectedSearchBarType = document.querySelector( 'input[name="searchBarType"][value="' + searchBarData.destinationsType.value + '"]' );
        if( selectedSearchBarType )
        {
            searchBarDateQuestion.innerHTML = selectedSearchBarType.dataset.priceTypeLabel;
            selectedSearchBarType.checked = true;
        }
    }
}

function clearSubDestinations()
{
    const destinationsPopup = document.getElementById( 'search-bar-destination-popup' );
    destinationsPopup.querySelector( '#popup-buttons' ).classList.remove( 'popup-buttons-destination-opened' );
    destinationsPopup.classList.remove( 'subdestinations-opened' );

    const subDestinations = document.getElementById( 'search-bar-subdestinations' );
    if( subDestinations )
    {
        subDestinations.remove();
    }

    const multipleDestinationsSelected = document.getElementById( 'multiple-destinations-selected' );
    if( multipleDestinationsSelected )
    {
        multipleDestinationsSelected.remove();
    }
}

async function checkAndResetAvailableDestinations( destinationsType, skipReset )
{
    let searchBarData = {};
    if( getCookie( 'dw_sbd' ) )
    {
        searchBarData = JSON.parse( getCookie( 'dw_sbd' ) );
    }
    const searchBarContainer = document.getElementById( 'search-bar-destination-container' );
    const searchBarQuestion = searchBarContainer.querySelector( '.search-input-question' );

    // reset search bar destinations if switching types with multiple destinations or subdestinations selected
    const searchBarSubDestinations = document.getElementById( 'search-bar-subdestinations' );
    let subDestinations = null;
    if( searchBarSubDestinations )
    {
        subDestinations = searchBarSubDestinations.querySelectorAll( 'input[name="levelTwoDestination"]:checked, input[name="levelThreeDestination"]:checked' );
    }

    const checkedInitialSearchBarDestinations = document.querySelectorAll( 'input[name="searchBarDestinations"]:checked' );
    if( checkedInitialSearchBarDestinations.length >= 2 || subDestinations?.length > 0 )
    {
        if( !skipReset )
        {
            searchBarQuestion.innerHTML = await getTranslatedString( 'whereDoYouWantToGo' );

            clearSubDestinations();

            searchBarData.countryAreas = null;
            searchBarData.parentDestination = null;
            searchBarData.seoFriendlyName = null;

            checkedInitialSearchBarDestinations.forEach( function( checkedInitialSearchBarDestination )
            {
                checkedInitialSearchBarDestination.checked = false;
            } );
        }
    }

    // toggle display of available destinations based on selected user destinationsType
    const countFor = destinationsType === 'liveaboards' ? 'pcCount' : destinationsType === 'accommodations' ? 'phCount' : 'totalCount';
    const destinationsContainer = document.querySelectorAll( '[data-destination-option]' );
    for( const destinationContainer of destinationsContainer )
    {
        // toggle destinations in interface based on selected user destinationsType
        destinationContainer.style.display = 'flex';
        if( destinationsType === 'liveaboards' && destinationContainer.dataset.pcCount === '0' )
        {
            destinationContainer.style.display = 'none';
        }

        if( destinationsType === 'accommodations' && destinationContainer.dataset.phCount === '0' )
        {
            destinationContainer.style.display = 'none';
        }

        if( ( destinationsType === 'offers' || destinationsType === 'all' || destinationsType === null ) && destinationContainer.dataset.phCount === '0' && destinationContainer.dataset.pcCount === '0' )
        {
            destinationContainer.style.display = 'none';
        }

        if( destinationContainer.style.display === 'none' )
        {
            const destinationContainerInput = destinationContainer.querySelector( 'input[type=checkbox]' );
            destinationContainerInput.checked = false;

            if( searchBarData.countryAreas && searchBarData.countryAreas.length > 0 )
            {
                const indexOfRemovedDestination = searchBarData.countryAreas.findIndex( function( countryArea )
                {
                    return destinationContainerInput.dataset.countryArea === countryArea.value;
                } );
                if( indexOfRemovedDestination > -1 )
                {
                    searchBarData.countryAreas.splice( indexOfRemovedDestination, 1 );
                }

                if( searchBarData.countryAreas.length === 0 )
                {
                    searchBarData.countryAreas = null;
                    searchBarData.parentDestination = null;
                    searchBarData.seoFriendlyName = null;

                    clearSubDestinations();

                    searchBarQuestion.innerHTML = await getTranslatedString( 'whereDoYouWantToGo' );
                }
            }
        }

        // update count in interface based on selected user destinationsType
        if( destinationContainer.dataset.totalCount )
        {
            destinationContainer.querySelector( '.filter-label-amount' ).innerHTML = '(' + destinationContainer.dataset[countFor] + ')';
        }
    }

    // after selection set multiple destinations if more than one destination is selected
    const checkedSearchBarDestinations = document.querySelectorAll( 'input[name="searchBarDestinations"]:checked' );
    if( checkedSearchBarDestinations.length > 1 )
    {
        searchBarQuestion.innerHTML = await getTranslatedString( 'multipleDestinationsSelected' );

        if( !skipReset )
        {
            clearSubDestinations();

            searchBarData.countryAreas = null;
            searchBarData.parentDestination = null;
            searchBarData.seoFriendlyName = null;
        }
    }

    // after selection set single destination if only one destination is selected
    if( checkedSearchBarDestinations.length === 1 )
    {
        searchBarData.parentDestination = checkedSearchBarDestinations[0].dataset.countryArea;
        searchBarData.seoFriendlyName = checkedSearchBarDestinations[0].dataset.seoFriendlyName;
        if( !skipReset )
        {
            searchBarQuestion.innerHTML = checkedSearchBarDestinations[0].value;
        }

        const subDestinationCount = document.getElementById( 'sub-destinations-all' );
        if( subDestinationCount )
        {
            subDestinationCount.innerHTML = '(' + checkedSearchBarDestinations[0].parentElement.dataset[countFor] + ')';
        }
    }

    if( getCookie( 'dw_sbd' ) )
    {
        setCookie( 'dw_sbd', JSON.stringify( searchBarData ) );
    }
}

function setCountForSelectedSearchBarType()
{
    const checkedSearchBarQuestion = document.querySelector( 'input[name="searchBarType"]:checked' );
    if( checkedSearchBarQuestion )
    {
        const destinationTypeCount = document.getElementById( 'destination-amount-' + checkedSearchBarQuestion.value ).dataset.destinationCount;
        const destinationCount = document.getElementById( 'destination-count' );
        destinationCount.innerHTML = '(' + destinationTypeCount + ')';
    }
}
